import * as React from 'react';
import { MdxConnection } from '../../types/graphql-types';
import { BlogCard } from './blog-card';

export function BlogPosts({
  nodes,
  children,
}: {
  nodes: MdxConnection['nodes'];
  children: JSX.Element | JSX.Element[];
}) {
  return (
    <main
      className="
      flex
      w-full
      justify-center
      items-center
      flex-col
      xl:flex-row
      xl:items-start
      "
    >
      <div role="left side menu" className="hidden xl:block xl:w-32"></div>
      <div
        className="
        flex
        flex-col
        justify-between
        mx-4
        sm:flex-row
        sm:flex-wrap
        sm:max-w-tabletmain
        lg:max-w-screen-md
        "
      >
        {nodes.map((node) => (
          <BlogCard key={node?.frontmatter?.slug} node={node}></BlogCard>
        ))}
        {nodes.length % 3 === 2 ? (
          <div className="hidden lg:block lg:w-minicard"></div>
        ) : null}
      </div>
      <div
        role="right side or footer menu"
        className="flex justify-center w-32"
      >
        {/* Year page links */}
        {children}
      </div>
    </main>
  );
}
