import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layouts/layout';
import { BlogPosts } from '../components/ui/blog-posts';
import { BlogYearPageLinks } from '../components/ui/blog-yearpage-links';
import { Query } from '../types/graphql-types';

const BlogPage = ({ data }: { data: Query }) => {
  const postedYearList = getYearsFromBlogPageProps(data);

  return (
    <Layout pageTitle="黄金虫ブログ">
      <BlogPosts nodes={data.allMdx.nodes}>
        <BlogYearPageLinks years={postedYearList}></BlogYearPageLinks>
      </BlogPosts>
    </Layout>
  );
};

export const query = graphql`
  query BlogPageQuery {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          title
          slug
          date
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
      }
    }
  }
`;

export default BlogPage;

// internal functions

export interface BlogYearMeta {
  year: number;
  count: number;
}

/**
 * 記事の書かれた年度の配列をArray({year: YYYY, count: number})の形式で返す。
 * @param data BlogPageProps['data']
 * @returns BlogYearMeta[]
 */
const getYearsFromBlogPageProps = (data: Query): BlogYearMeta[] => {
  const duplicateArray = data.allMdx.nodes.map((node) => {
    if (node?.frontmatter?.date === undefined) {
      throw new Error('Invalid value. node: ' + JSON.stringify(node));
    }

    return new Date(node.frontmatter.date).getFullYear();
  });

  return Array.from(new Set(duplicateArray)).map((y) => ({
    year: y,
    count: duplicateArray.filter((dy) => dy === y).length,
  }));
};
