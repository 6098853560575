import { Link } from 'gatsby';
import * as React from 'react';
import { BlogYearMeta } from '../../pages/blog';

export function BlogYearPageLinks({ years }: { years: BlogYearMeta[] }) {
  return (
    <div
      className="
      m-4"
    >
      <ul>
        {years.map((y) => (
          <li className="hover:opacity-60 mb-2" key={y.year}>
            <Link to={'/blog/year/' + y.year}>{`${y.year}(${y.count})`}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
