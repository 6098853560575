import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Mdx } from '../../types/graphql-types';

export function BlogCard({ node }: { node: Mdx }) {
  if (!node.frontmatter) {
    throw new Error('Invalid data. node: ' + JSON.stringify(node));
  }

  return (
    <div
      className="
      border-2
      rounded-lg
      mb-4
      max-w-sm
      sm:max-w-card
      lg:max-w-minicard
      "
    >
      <Link to={node.frontmatter.slug || ''}>
        <GatsbyImage
          alt="Blog hero image"
          image={node.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
          className="
            rounded-t-lg
            object-cover
            w-full
            h-36
            "
        ></GatsbyImage>
        <p className="text-pink-700 text-center">
          Posted: {node.frontmatter.date}
        </p>
        <p className="text-2xl text-gray-600 text-center">
          {node.frontmatter.title}
        </p>
      </Link>
    </div>
  );
}
